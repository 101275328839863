@import "styles/font-size";
@import "styles/colors";
@import "styles/media";

.component {
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  border: none;
  text-decoration: none !important;
  border-radius: 9999px;
  font-weight: 500;
  box-shadow: 0 8px 10px -10px rgba(#000, 0.06);
  cursor: pointer;

  &.hasIcon.before {
    flex-flow: row-reverse nowrap;
  }
}

// Sizes

.component.large {
  @include font-size(large);

  padding: 13px 35px;

  &.hasIcon.before {
    padding-left: 25px;

    .text {
      margin-left: 15px;
    }
  }

  &.hasIcon.after {
    padding-right: 25px;

    .text {
      margin-right: 25px;
    }
  }
}

.component.medium {
  @include font-size(base);

  padding: 11px 20px;

  @include for-small-phones-only {
    font-size: rem(13px);
  }

  &.hasIcon.before {
    padding-left: 15px;

    .text {
      margin-left: 10px;
    }
  }

  &.hasIcon.after {
    padding-right: 15px;

    .text {
      margin-right: 15px;
    }
  }
}

.component.small {
  @include font-size(small);

  padding: 8px 18px;

  &.hasIcon.before {
    padding-left: 8px;

    .text {
      margin-left: 6px;
    }
  }

  &.hasIcon.after {
    padding-right: 8px;

    .text {
      margin-right: 8px;
    }
  }
}

// Color themes

.component.white {
  color: $color-green-dark;
  background-color: $color-white;

  &.hasBorder {
    border: solid 1px $color-white-on-white-border;
  }
}

.component.green {
  color: $color-white;
  background-color: $color-green-dark;
}

.component.greenBright {
  color: $color-white;
  background-color: $color-green-bright;
}

.component.red {
  color: $color-white;
  background-color: $color-red-dull;
}
