@import "styles/rem";
@import "styles/colors";

.component {
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: $color-gray;
  cursor: pointer;

  &:global(.active) {
    color: $color-green-dark;
  }

  &.isDisabled {
    pointer-events: none;
  }
}

.label {
  font-size: rem(10px);
  margin-bottom: 1px;
}

.icon {
  margin-bottom: 2px;
}
