@import "styles/media";
@import "styles/colors";
@import "styles/font-size";

.component {
  background-color: #dddde1;
  height: 100%;
}

.main {
  background-color: $color-black;
  position: relative;
  z-index: 1;
  height: 100%;
  overflow: hidden;

  @include for-tablet-portrait-up {
    max-width: $app-max-width;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0 0 50px 0 rgba(#000, 0.05);
  }
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 2;

  @include for-tablet-portrait-up {
    left: 50%;
    transform: translateX(-50%);
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
}
