@import "styles/colors";

@keyframes spin {
  0% { transform: rotate(0deg) translateZ(0); }
  100% { transform: rotate(360deg) translateZ(0); }
}

.component,
.component:after {
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.component {
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  animation: spin 0.35s linear infinite;
}

.dark {
  border-top: 2px solid rgba($color-gray, 0.2);
  border-right: 2px solid rgba($color-gray, 0.2);
  border-bottom: 2px solid rgba($color-gray, 0.2);
  border-left: 2px solid $color-green-dark;
}

.light {
  border-top: 2px solid rgba($color-white, 0.2);
  border-right: 2px solid rgba($color-white, 0.2);
  border-bottom: 2px solid rgba($color-white, 0.2);
  border-left: 2px solid $color-white;
}

.large {
  margin: 15px auto;
}

.small {
  margin: 10px auto;
  width: 16px;
  height: 16px;

  &::after {
    width: 16px;
    height: 16px;
  }
}
