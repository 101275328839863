@import "styles/navigation";
@import "styles/colors";

.component {
  @include offset-navigation;

  background-color: $color-background;
  height: 100%;
}

.back {
  margin-top: 10px;
  margin-bottom: 30px;
}

.header {
  padding-left: 10px;
  padding-right: 10px;
}

.content {
  position: relative;
}

.image {
  padding-top: 50%;
  border-radius: 26px;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.title {
  margin-bottom: 25px;
}
