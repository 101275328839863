@import "styles/navigation";
@import "styles/colors";

.component {
  @include offset-navigation;

  background-color: $color-background;
  height: 100%;
}

.back {
  margin-top: 10px;
  margin-bottom: 30px;
}

.header {
  padding-left: 10px;
  padding-right: 10px;
}

.allergensHeader {
  margin-top: 40px;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 20px;
}

.filters {
  margin-top: 30px;
  margin-bottom: 20px;
}
