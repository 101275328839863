@import "styles/colors";

.component {
  position: relative;
}

.tabs {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
  justify-content: space-evenly;
}
