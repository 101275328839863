@import "styles/font-size";
@import "styles/colors";

.component {
  position: relative;
  line-height: 1;
  white-space: nowrap;

  &.hasUnsuitables {
    display: inline-block;
    background-color: rgba($color-danger, 0.2);
    color: $color-danger;
    padding: 1px 8px 3px;
    line-height: 1;
    border-radius: 9999px;
    margin-top: 3px;
    margin-right: -2px;
    text-align: center;
  }

  &.allowWrap {
    white-space: normal;
  }
}

.diet {
  @include font-size(extra-small);

  display: inline;
  position: relative;

  &::after {
    content: ', ';
  }

  &:last-child {

    &::after {
      content: '';
    }
  }

  .large & {
    @include font-size(base);

    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: solid 1px $color-text;
    text-align: center;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    // overflow: hidden;
    margin-right: 3px;
    margin-bottom: 3px;

    span {
      @include font-size(extra-small);

      position: absolute;
      bottom: -3px;
      left: 50%;
      transform: translate(-50%, 100%);
    }

    &::after {
      content: '';
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
