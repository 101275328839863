@import "styles/colors";

.component {
  position: relative;
  border: none;
  border-radius: 15px 15px 0 0;
  width: 100%;
  background-color: $color-gray-light;
  color: $color-link;
  font-weight: 500;
  padding: 13px 5px;
  transform-origin: center bottom;
  cursor: pointer;

  &.isActive {
    background-color: $color-white;
    cursor: auto;
  }
}
