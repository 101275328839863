@import "styles/colors";

.component {
  position: relative;

  & + .component {
    margin-top: 10px;
  }
}

.time {
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 1px;

  &:last-child {
    margin-bottom: 0;
  }

  &.isException {
    color: $color-red-dull;
  }
}

.time:not(.isException) + .time.isException {
  margin-top: 10px;
}

.title {
  margin-bottom: 8px;
}

.label {
  width: 50%;
}

.hours {
  width: 50%;
}
