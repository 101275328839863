@import "styles/colors";

.component {
  background-color: $color-white;
  box-shadow: 0 8px 10px -10px rgba(#000, 0.06);
  padding: 0 20px;
  border-radius: 25px;
}

.item {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 20px;
  border-top: solid 1px #F0F0F0;
}

.title {
  padding-left: 3px;
  margin-right: 20px;
}
