@import "styles/colors";
@import "styles/font-size";

.component {
  position: relative;
  padding: 25px 0 60px;
  overflow: hidden;
}

.title {
  text-align: left;
  padding: 0 25px;
}

.slider {
  margin-top: 25px;
  display: flex;
  flex-flow: row nowrap;
  flex-shrink: 0;
}

.week {
  width: 100%;
  flex-shrink: 0;
  padding-left: 20px;
  padding-right: 20px;
}

.label {
  @include font-size(small);

  color: $color-text;
  font-weight: 400;
  margin-bottom: 12px;
}

.item {
  margin-bottom: 5px;

  &:last-child {
    margin-bottom: 0;
  }
}

.controls {
  position: absolute;
  top: 75px;
  left: 25px;
  right: 25px;
  height: 20px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.previous {
  margin-right: auto;
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
}

.next {
  margin-left: auto;
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
}
