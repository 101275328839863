@import "styles/font-size";
@import "styles/colors";
@import "styles/easing";

.component {
  text-align: left;
  position: relative;
  background-color: $color-white;
  border-radius: 25px;
  box-shadow: 0 10px 18px -10px rgba(#000009, 0.07);

  &.hasError {
    .header {
      opacity: 0.25;
    }
  }

  &.isClosed {
    opacity: 0.5;

    .header {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      padding-right: 20px;
      cursor: default;
    }

    .title {
      color: $color-text;
    }
  }
}

.header {
  position: relative;
  padding: 16px 73px 17px 0px;
  cursor: pointer;
  background-color: #fff;
  border-radius: 25px;
  transition: all 0.25s $ease-out-expo;
}

.icon {
  position: absolute;
  top: 25px;
  left: 20px;
  transform: translateY(-50%);
}

.title {
  @include font-size(h4);

  color: $color-interactive;
  max-width: 80%;
  padding-left: 24px;
}

.headerMessage {
  @include font-size(small);

  color: $color-text;
}

.wrapper {
  height: auto;
  overflow: hidden;
}

.menus {
  border-top: solid 1px $color-gray-light;
  height: auto;
  overflow: hidden;
}

.errorMessage {
  height: auto;
  border-top: solid 1px $color-gray-light;
  padding-top: 10px;
  padding-bottom: 15px;
  padding-left: 23px;
  padding-right: 23px;
  text-align: center;
  overflow: hidden;
  color: $color-danger;
}

.detailsTrigger {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  background: transparent;
  height: 51px;
  width: 50px;
  z-index: 2;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  padding: 0;
  cursor: pointer;
}

