.component {
  overflow: hidden;
}

.wrapper {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-left: 23px;
  padding-right: 23px;
  padding-bottom: 15px;

  &::before,
  &::after {
    content: '';
    height: 1px;
    background-color: #909193;
    opacity: 0.3;
    flex: 1;
  }
}

.text {
  font-size: 13px;
  flex-shrink: 0;
  padding-left: 10px;
  padding-right: 10px;
}
