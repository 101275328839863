@import "styles/navigation";
@import "styles/colors";

.component {
  @include offset-navigation;

  background-color: $color-background;
  height: 100%;
}

.back {
  margin-top: 10px;
  margin-bottom: 30px;
}

.header {
  padding-left: 10px;
  padding-right: 10px;
}

.content {
  position: relative;
  padding-left: 10px;
  padding-right: 10px;

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
      padding: 0;
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-end;
      justify-content: space-between;
    }
  }
}

.title {
  margin-bottom: 25px;
}

.price {
  font-weight: 500;
  color: $color-heading;
}
