@import "styles/font-size";
@import "styles/colors";

.component {
  @include font-size(base);

  cursor: pointer;
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
  border: solid 1px $color-gray-light;
  border-radius: 9999px;
  width: 41px;
  height: 41px;
  margin-left: auto;
  margin-right: 10px;
  background-color: $color-white;
  color: $color-green-dark;
  box-shadow: 0 8px 10px -10px rgba(#000, 0.06);
  padding: 0;

  svg {
    width: 24px;
    height: 24px;
    transform: rotate(0deg);
  }
}
