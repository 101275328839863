@import "styles/navigation";
@import "styles/colors";

.component {
  position: relative;
  background: $color-white;
  height: $height-navigation;
  height: calc(#{$height-navigation} + env(safe-area-inset-bottom));
  border-top: solid 1px $color-background;
}

.list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  height: $height-navigation;
  width: 100%;
}

.item {
  flex: 1;
  text-align: center;
  height: 100%;
}
