@import "styles/navigation";
@import "styles/colors";

.component {
  @include offset-navigation;

  background-color: $color-background;
  height: 100%;
}

.back {
  margin-top: 10px;
  margin-bottom: 30px;
}

.header {
  padding-left: 10px;
  padding-right: 10px;
}

.content {
  position: relative;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 25px;
}

.title {
  margin-bottom: 25px;
}

.inlineIcon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  margin-right: 2px;
  background-color: #fff;
  width: 18px;
  height: 18px;
  border-radius: 2px;
  box-shadow: 0 2px 8px -2px rgba(#000, 0.5);

  svg {
    height: 10px;
    width: 10px;
  }
}
