@import "styles/font-size";
@import "styles/colors";

.component {
  @include font-size(extra-large);

  position: relative;
  padding: 15px 35px 15px 60px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  background-color: transparent;
  border: none;
  text-decoration: none !important;
  border-radius: 9999px;
  font-weight: 500;
  box-shadow: 0 8px 10px -10px rgba(#000, 0.06);
  cursor: pointer;

  &.noIcon {
    padding-left: 28px;
  }
}

.icon {
  position: absolute;
  top: 0;
  left: 10px;
  width: 50px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.arrow {
  position: absolute;
  top: 0;
  right: 20px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

// Color themes

.component.white {
  color: $color-green-dark;
  background-color: $color-white;

  &.hasBorder {
    border: solid 1px $color-white-on-white-border;
  }
}

.component.green {
  color: $color-white;
  background-color: $color-green-dark;

  .icon, .arrow {
    path {
      stroke: $color-white;
    }
  }
}
