@import "styles/colors";
@import "styles/font-size";

.component {
  position: relative;
  border-radius: 26px;
  box-shadow: 0 8px 10px -10px rgba(#000, 0.06);
  background-color: #fff;
}

.image {
  border-top-left-radius: 26px;
  border-top-right-radius: 26px;
  height: 125px;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.content {
  padding: 20px 25px 25px;
}

.title {
  margin-bottom: 15px;
}

.link {
  @include font-size(base);

  color: $color-link;
  font-weight: 500;
  margin-top: 20px;
}

.linkText {
  display: inline-block;
  margin-right: 10px;
}
