.component {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: solid 1px #F0F0F0;

  &:last-child {
    border-bottom: 0;
  }
}

.title {
  padding-left: 3px;
  margin-right: 20px;
}
