@import "styles/font-size";
@import "styles/colors";
@import "styles/easing";

// .component {
//   position: relative;
//   border: solid 1px rgba(#000, 0.05);
//   border-radius: 9999px;
//   display: flex;
//   flex-flow: row nowrap;
//   align-items: center;
//   padding-left: 20px;
//   margin-bottom: 10px;
//   box-shadow: 0 0 0 0 rgba(#000, 0);
//   transition: all 0.5s $ease-out-expo;
// }

// .checked {
//   background-color: #fff;
//   border-color: transparent;
//   box-shadow: 0 8px 10px -10px rgba(#000, 0.06);
// }

.component {
  @include font-size(extra-large);

  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  background-color: transparent;
  border: none;
  text-decoration: none !important;
  border-radius: 9999px;
  font-weight: 500;
  box-shadow: 0 8px 10px -10px rgba(#000, 0.06);
  cursor: pointer;
  color: $color-green-dark;
  background-color: $color-white;
  margin-bottom: 10px;
  transition: all 0.25s $ease-out-expo;
}

.checked {
  color: $color-white;
  background-color: $color-green-bright;
}

.input {
  position: relative;
  opacity: 0;
  position: absolute;
  cursor: pointer;
}

.label {
  padding: 15px 35px 15px 28px;
  display: block;
  flex: 1;
  cursor: pointer;
}
