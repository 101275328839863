@import "styles/navigation";
@import "styles/font-size";
@import "styles/colors";
@import "styles/media";

.component {
  @include offset-navigation;

  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
  z-index: 999999;
}

.container {
  min-height: 200px;
  position: relative;
  margin: 10px 10px 60px 10px;
  background-color: $color-white;
  border-radius: 24px;
  z-index: 2;
}

.content {
  position: relative;
  z-index: 1;
}

.closeTop {
  @include font-size(small);

  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 5px;
  padding: 10px 15px;
  background-color: transparent;
  border: none;
  color: $color-green-dark;
  font-weight: 500;
  z-index: 2;
}

.closeBottom {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  z-index: 2;
}
