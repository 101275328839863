@import "styles/colors";
@import "styles/font-size";

.component {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999999;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-end;

  @include for-tablet-portrait-up {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
}

.background {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(#000, 0.3);
}

.content {
  position: relative;
  z-index: 2;
  padding: 20px 25px;
  margin-top: 25px;
  margin-bottom: 25px;
  margin-bottom: calc(25px + env(safe-area-inset-bottom));
  width: 90%;
  background-color: $color-white;
  border-radius: 12px;
  text-align: center;
}

.buttons {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
}

.settingsButton {
  @include font-size(small);

  color: $color-link;
  padding: 8px 0px;
  background-color: transparent;
  border: none;
  margin-right: 10px;
  cursor: pointer;
}

.allowButton {
  @include font-size(base);

  background-color: $color-link;
  color: $color-white;
  padding: 8px 20px;
  border-radius: 9999px;
  border: none;
  margin-left: 10px;
  cursor: pointer;
}
