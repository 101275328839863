@import "styles/font-size";
@import "styles/colors";

.component {
  padding-right: 50px;
}

.large {
  @include font-size(h1);
}

.small {
  @include font-size(h4);
}

.supplementary {
  color: $color-text;
  font-weight: 400;
  display: block;
  margin-top: 2px;
}
