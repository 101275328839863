@import "styles/colors";
@import "styles/font-size";

.component {
  position: relative;
  padding-top: 10px;
  padding-bottom: 12px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.title {
  position: relative;
  width: 100%;
}

.name {
  @include font-size(h4);
}

.supplementaryName {
  color: $color-text;
  font-weight: 400;
  display: block;
}

.meta {
  text-align: right;
  margin-left: 20px;
}

.diets {
  @include font-size(extra-small);

  margin-top: 2px;
  white-space: nowrap;
}

.diet {
  display: inline;

  &::after {
    content: ', ';
  }

  &:last-child {

    &::after {
      content: '';
    }
  }
}

.extra {
  @include font-size(small);

  margin-top: 2px;
  color: #b1b1b4;
}

.label {
  display: inline;
  font-style: italic;
}

.climateChoiceLabel {
  display: inline-block;
  font-size: 0.9rem;
  font-weight: 700;
  color: $color-green-dark;
  text-transform: uppercase;
  background-color: #e5f3e5;
  padding: 0.3rem 0.9rem;
  border-radius: 0.9rem;
  margin-top: 0.9rem;
}

.allergens {
  display: inline;

  .label + &::before {
    content: ' – ';
  }
}
