.component {
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  z-index: 0;

  &::-webkit-scrollbar {
    display: none;
  }

  &.isLocked {
    overflow: hidden;
  }
}
