/**
 * Define project colors here.
 * You can also skip this entirely and just define contextual variables.
 */
$color-black:                 #000000;
$color-white:                 #ffffff;
$color-green-dark:            #155C28;
$color-green-bright:          #4FDD75;
$color-green-dull:            #85DD5B;
$color-red-dull:              #FC6C83;
$color-gray:                  #909193;
$color-gray-very-light:       #F3F3F5;
$color-gray-light:            #EDEDF0;
$color-yellow-light:          #FFF6BF;

/**
 * Bind colors to meaningful contextual variables.
 */
$color-background:            $color-gray-very-light;
$color-heading:               $color-black;
$color-text:                  $color-gray;
$color-link:                  $color-green-dark;
$color-white-on-white-border: #EBEBEE;
$color-interactive:           $color-green-dark;
$color-danger:                $color-red-dull;

$color-theme-vegan:           #B2D498;
$color-theme-vegetarian:      #94DB8A;
$color-theme-fish:            #98D2DD;
$color-theme-dessert:         #E4A7F5;
$color-theme-lunch:           #F8C27E;
$color-theme-special:         $color-theme-lunch;
