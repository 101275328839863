.component {
  overflow: hidden;
}

.wrapper {
  padding-top: 35px;
  padding-bottom: 30px;
}

.image {
  width: 181px;
}
