@import "styles/navigation";
@import "styles/colors";

.component {
  @include offset-navigation;

  background-color: $color-background;
  height: 100%;
}

.wrapper {
  padding-top: 40px;
  padding-bottom: 40px;
}

.content {
  padding-left: 10px;
  padding-right: 10px;
}

.buttons {
  margin-top: 35px;
}
