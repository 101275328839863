@import "styles/colors";
@import "styles/font-size";

.component {
  position: relative;
}

.foods {
  position: relative;
  padding-top: 2px;
  padding-bottom: 6px;
  padding-left: 23px;
  padding-right: 23px;
}

.food {
  border-bottom: solid 1px $color-gray-light;

  &:last-child {
    border-bottom: none;
  }
}

.hiddenFoods {
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-flow: row nowrap;
  height: 45px;
}

.hiddenFoodsText {
  @include font-size(small);

  color: rgba(#000, 0.47);
}

.hiddenFoodsToggle {
  @include font-size(small);

  background: none;
  border: none;
  color: $color-green-dark;
  padding: 3px 10px;
  margin-right: -10px;
}

.unsuitableFoodWarning {
  @include font-size(small);

  padding-top: 15px;
  padding-bottom: 7px;
  color: rgba(#000, 0.47);
}

.error {
  font-size: rem(14px);
  text-align: center;
  padding-top: 10px;
  padding-bottom: 15px;
  color: $color-text;
  padding-left: 23px;
  padding-right: 23px;
  opacity: 0.5;
  background-color: rgba(#000, 0.05);
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;

  p {
    max-width: 50ch;
    margin-left: auto;
    margin-right: auto;
  }
}

.announcements {
  border-top: solid 1px rgba(#8e8863, 0.1);
  opacity: 1;
  background-color: rgba(255,246,191,.5);
  color: #8e8863;
  padding-left: 23px;
  padding-right: 23px;
  padding-top: 6px;
  padding-bottom: 12px;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  line-height: 1;
}
