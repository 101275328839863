@import "styles/font-size";
@import "styles/colors";
@import "styles/media";

.component {
  @include font-size(base);

  @include for-small-phones-only {
    font-size: rem(13px);
  }

  cursor: pointer;
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  white-space: nowrap;
  align-items: center;
  border: solid 1px $color-gray-light;
  border-radius: 9999px;
  padding: 11px 17px;
  font-weight: 500;
  background-color: $color-white;
  color: $color-green-dark;
  box-shadow: 0 8px 10px -10px rgba(#000, 0.06);
}

.indicator {
  background-color: $color-green-dull;
  border-radius: 50%;
  width: 11px;
  height: 11px;
  margin-right: 8px;
}

.date {
  text-transform: capitalize;
}
