@import "styles/font-size";
@import "styles/colors";

.component {
  position: relative;
  overflow: hidden;
  border-radius: 26px;
  box-shadow: 0 8px 10px -10px rgba(#000, 0.06);
  text-align: left;
  background-color: #fff;
  margin-bottom: 10px;
}

.title {
  @include font-size(h4);

  color: $color-link;
  font-weight: 500;
  border: none;
  background: transparent;
  border-radius: 26px;
  padding: 0;
  text-align: left;
  padding: 15px 25px 17px;
  width: 100%;
  cursor: pointer;
}

.wrapper {
  height: auto;
  overflow: hidden;
}

.text {
  padding: 0 25px 25px;
}
