@import "styles/colors";

.component {
  cursor: pointer;
  background-color: $color-green-dark;
  border: none;
  width: 68px;
  height: 68px;
  border-radius: 50%;
  box-shadow: 0 10px 40px -10px rgba(#000, 0.45);
  display: flex;
  align-items: center;
  justify-content: center;
}
