.component {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  path,
  line,
  circle {
    stroke: currentColor;
  }
}
