@import "styles/easing";
@import "styles/font-size";
@import "styles/colors";

.component {
  position: relative;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: transparent;
  transition: all 0.25s $ease-out-expo;

  &:first-child {
    margin-top: 10px;
  }

  &:last-child {
    margin-bottom: 15px;
  }

  &.isExpanded {
    padding-top: 0;
    padding-bottom: 0;
    border-top: solid 1px rgba(#000, 0.05);
    border-bottom: solid 1px rgba(#000, 0.05);
    margin-top: 10px;
    margin-bottom: 10px;
    box-shadow: 0 0 50px 0 rgba(#000, 0.1) inset;

    &:first-child {
      border-top: none;
      margin-top: 0;
    }

    &:last-child {
      border-bottom: none;
      margin-bottom: 0;
    }

    .wrapper {
      padding-bottom: 15px;
    }

    .title {
      font-weight: 400;
      color: #a8a8a8;
    }
  }
}

.component.isExpanded + .component.isExpanded {
  margin-top: -10px;
}

.wrapper {
  height: 0;
  overflow: hidden;
  z-index: 1;
  transform-origin: center top;
}

.header {
  position: relative;
  padding: 18px 23px 19px;
  cursor: pointer;
  background-color: $color-white;
  border-radius: 9999px;
  box-shadow: 0 10px 18px -10px rgba(#000009, 0.07);
  z-index: 2;
}

.icon {
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
}

.title {
  @include font-size(h3);

  color: $color-interactive;
  padding: 0 24px;
}

.restaurant {
  margin-top: 10px;
  transform-origin: center top;

  &:first-child {
    margin-top: 0;
  }
}
