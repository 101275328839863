@import "styles/font-size";
@import "styles/colors";

.component :global {

  // Set all edge margins to 0
  h1, h2, h3, h4, h5, h6, p, blockquote {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  // Headings
  h1, .h1 {
    margin-top: 30px;
    margin-bottom: 20px;
  }

  h2, .h2 {
    margin-top: 30px;
    margin-bottom: 20px;
  }

  h3, .h3 {
    margin-top: 30px;
    margin-bottom: 20px;
  }

  h4, .h4 {
    margin-top: 30px;
    margin-bottom: 20px;
  }

  h5, .h5 {
    margin-top: 25px;
    margin-bottom: 20px;
  }

  h6, .h6 {
    margin-top: 25px;
    margin-bottom: 20px;
  }

  // Text elements
  p {
    line-height: 1.5;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  ul,
  ol {
    margin-top: 20px;
    margin-bottom: 20px;
    padding-left: 15px;
    margin-left: 30px;

    &:first-child {
      margin-top: 10px;
    }

    li {
      margin-bottom: 5px;

      ul, ol {
        margin-bottom: 20px;
      }
    }
  }

  blockquote {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  strong {
    font-weight: 700;
  }

  // Links

  a {
    font-weight: 500;
    text-decoration: underline;
    color: $color-link;
  }

  // Other

  hr {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  table {
    width: 100%;
    border-spacing: 0;
    border-right: 1px solid rgba(#000, 0.15);
    border-bottom: 1px solid rgba(#000, 0.15);

    td,
    th {
      padding: 15px;
      text-align: left;
      border-top: 1px solid rgba(#000, 0.15);
      border-left: 1px solid rgba(#000, 0.15);
    }

    th {
      background: rgba(#000, 0.05);
    }
  }
}
