@import "styles/font-size";

.component {
  padding-top: 10px;
  padding-bottom: 10px;
}

.restaurant {
  margin-top: 10px;
  transform-origin: center top;

  &:first-child {
    margin-top: 0;
  }
}

.header {
  @include font-size(small);
}
