@import "styles/rem";
@import "styles/colors";

.component {
  position: relative;
  padding: 30px 25px 50px 25px;
  text-align: left;
}

.name {
  margin-bottom: 20px;
}

.diets {
  margin-top: 12px;
}

.allergens {
  margin-top: 28px;
}

.allergenList {

  &::first-letter {
    text-transform: capitalize;
  }
}

.subtitle {
  margin-bottom: 5px;
}

.label {
  font-size: rem(31px);
  font-family: 'Hellotropica', 'Lineto', Arial, Helvetica, sans-serif;
  margin-top: 5px;
  color: $color-black;
  transform: rotate(-3.5deg) translateX(-5px);;
}

.ingredients {
  margin-top: 20px;
}

.nutrition {
  margin-top: 20px;
  padding: 20px 15px 25px;
  border-radius: 10px;
  background-color: #FFF6BF;
  color: $color-black;
}

.prices {
  margin-top: 20px;
}

.price {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}
