@import "styles/font-size";
@import "styles/colors";

.component {
  font-weight: 700;
  text-transform: uppercase;
}

$categories: 'vegan', 'vegetarian', 'fish', 'dessert', 'special', 'lunch';
$colors: $color-theme-vegan, $color-theme-vegetarian, $color-theme-fish, $color-theme-dessert, $color-theme-special, $color-theme-lunch;

@each $category in $categories {
  $i: index($categories, $category);

  .#{$category} {
    color: nth($colors, $i);
  }
}

.small {
  @include font-size(extra-small);
}

.large {
  @include font-size(large);
}
