@import "styles/colors";

.component {
  position: relative;
  padding: 30px 25px 50px 25px;
  text-align: left;
}

.title {
  margin-bottom: 20px;
}

.address {
  margin-bottom: 2px;
}

.maplink {
  color: $color-link;
  font-weight: 500;
  text-decoration: none;
}

.visitingHours {
  margin-top: 25px;
}

.section {
  margin-top: 30px;
}

.subtitle {
  margin-bottom: 15px;
}

.email {
  color: $color-link;
  font-weight: 500;
  text-decoration: none;
  margin-top: 3x;
}

.feedback {
  margin-top: 20px;
}
