@import "styles/navigation";
@import "styles/colors";

.component {
  @include offset-navigation;

  background-color: $color-background;
  height: 100%;
}

.back {
  margin-top: 10px;
  margin-bottom: 30px;
}

.header {
  padding-left: 10px;
  padding-right: 10px;
}

.content {
  position: relative;
  padding-bottom: 25px;
}

.title {
  margin-bottom: 25px;
}

.cookies {
  padding-top: 20px;
  padding-left: 25px;
  padding-right: 25px;
  border-radius: 10px;
  background-color: #fff;
  margin-top: 20px;
  margin-bottom: 30px;
}

.switch {
  margin-top: 20px;
  border-top: solid 1px #f2f2f2;
}

.legal {
  padding-left: 10px;
  padding-right: 10px;
}
