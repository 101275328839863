@import "~normalize.css";
@import "styles/font-size";
@import "styles/colors";

@font-face {
    font-family: 'Lineto';
    src: url('fonts/lineto-circular-book.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Lineto';
    src: url('fonts/lineto-circular-bookitalic.woff') format('woff');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Lineto';
    src: url('fonts/lineto-circular-medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Lineto';
    src: url('fonts/lineto-circular-mediumitalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Lineto';
    src: url('fonts/lineto-circular-bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Lineto';
    src: url('fonts/lineto-circular-bolditalic.woff') format('woff');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Hellotropica';
    src: url('fonts/Hellotropica.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

/**
 * Base styles of the root elements, like html and body
 */
html {
    background-color: $color-background;
    box-sizing: border-box;
    height: 100%;
    overflow: hidden;

    /**
     * Let's ensure that font size a11y features work properly.
     *
     * However, from a design perspective It's not recommended to automatically
     * scale font sizes by changing the root font size with media queries.
     */
    font-size: 0.625rem;
}

*,
*:before,
*:after {
    box-sizing: inherit;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent;
}

body {
    @include font-size(base);

    background-color: $color-background;
    color: $color-text;
    font-family: 'Lineto', Helvetica, Arial, sans-serif;
    font-weight: 400;
    line-height: 1.28;
    height: 100%;
    overflow: hidden;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#root {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

/**
 * Reset text element margins and paddings
 */
h1, h2, h3, h4, h5, h6,
p, ul, ol, blockquote, hr {
  margin: 0;
  padding: 0;
}

ul {
  list-style-type: none;
}

/**
 * Headings
 */
h1, .h1 {
  @include font-size(h1);

  color: $color-heading;
  font-weight: 500;
  line-height: 1.15;
}

h2, .h2 {
  @include font-size(h2);

  color: $color-heading;
  font-weight: 500;
  line-height: 1.15;
}

h3, .h3 {
  @include font-size(h3);

  color: $color-heading;
  font-weight: 500;
  line-height: 1.15;
}

h4, .h4 {
  @include font-size(h4);

  color: $color-heading;
  font-weight: 500;
  line-height: 1.15;
}

h5, .h5 {
  @include font-size(h5);

  color: $color-heading;
  font-weight: 500;
  line-height: 1.15;
}

h6, .h6 {
  @include font-size(h6);

  color: $color-heading;
  font-weight: 500;
  line-height: 1.15;
}

/**
 * Links
 */
a {
  text-decoration: none;
  color: inherit;
}

/**
 * Forms
 */
html,
button,
input,
select,
textarea {
    font-family: inherit;
    line-height: inherit;
}

/**
 * Images
 */
img {
    vertical-align: middle;
    max-width: 100%;
    height: auto;
}

/**
 * Prevent focus outlines unless the user has pressed the tab key.
 */
html:not(.has-user-tabbing) *:focus {
    outline: none;
}

/**
 * Customize focus effect to use box shadow instead
 */
html.has-user-tabbing *:focus {
    outline: none;
    box-shadow: 0 0 0 1px $color-green-bright inset !important;
}
