@import "styles/colors";

.component {
  position: relative;
  width: 44px;
  height: 22px;
  border: none;
  background-color: transparent;
  z-index: 1;
}

.background {
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
  z-index: 1;
  border: solid 1px #C8C8C8;
  border-radius: 9999px;
}

.button {
  position: absolute;
  top: 0;
  left: 0;
  width: 22px;
  height: 22px;
  border-radius: 100%;
  border: solid 1px #C8C8C8;
  background-color: $color-white;
  z-index: 2;
}
