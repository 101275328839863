.component {
  margin-bottom: 30px;

  > * {
    display: block;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
