@import "styles/font-size";
@import "styles/colors";

.component {
  @include font-size(large);

  position: relative;
  background-color: $color-white;
  color: $color-green-dark;
  cursor: pointer;
  width: 100%;
  padding: 10px 15px;
  border: solid 1px $color-gray-light;
  border-radius: 9999px;
  box-shadow: 0 8px 10px -10px rgba(#000, 0.06);
  font-weight: 500;
  text-transform: capitalize;

  &.isSelected {
    background-color: $color-green-dark;
    color: $color-white;
  }
}

.indicator {
  position: absolute;
  top: 50%;
  left: 18px;
  transform: translateY(-50%);
  background-color: $color-green-dull;
  border-radius: 50%;
  width: 11px;
  height: 11px;
  margin-right: 8px;
}
